<template>
  <div class="container" v-if="version">
    <el-row class="box">
      <el-col :span="24">
        版本号：
        <span>{{ version.latest }}</span>
      </el-col>
      <el-col style="margin-top: 30px;" :span="24">
        <el-button type="primary" icon="el-icon-download" @click="handleDownload">立即下载</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      os: '',
      version: null
    }
  },
  mounted() {
    this.getOs()
    this.getVersion()
    this.checkNavigator()
  },
  methods: {
    getOs() {
      if (/(Android)/i.test(navigator.userAgent)) {
        this.os = 'android'
      } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        this.os = 'ios'
      }
    },
    getVersion() {
      this.$api.client.version.info(this.os).then(response => {
        this.version = response
      })
    },
    checkNavigator() {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('micromessenger') > -1 || userAgent.indexOf('alipay') > -1) {
        this.$notify({
          message: '请点击右上角在浏览器中打开',
          position: 'top-right',
          duration: 0,
          showClose: false
        })
      }
    },
    handleDownload() {
      window.open(this.version.package, '_blank')
    }
  }
}
</script>

<style>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.box {
  margin-top: 200px;
  text-align: center;
}
</style>